/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 9000; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #4E3589; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  font-family: 'Silka';
}
.sidenav > .inner {
  width: 600px;
}

@media screen and (max-width: 768px) {
  .sidenav > .inner {
    width: 300px;
  }

  .showingg {
    width: 300px !important;
  }
}

.showingg {
  width: 600px;
}

.sidenav > .inner > .image-wrapper-cast {
  width: 100%;
  aspect-ratio: 4/3;
  position: relative;
}

.sidenav > .inner > .image-wrapper-cast > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidenav > .inner > .image-wrapper-cast::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(to top, #4E3589, transparent);
  pointer-events: none; /* Isso evita que o gradiente interfira com os elementos subjacentes */
}


/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 25px;
  font-size: 36px;
  z-index: 9999;
  color: white;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


.castSocials {
  display: flex;
  flex-direction: row;
}

.castSocials .fa-stack {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.castSocials .fa-stack-1x::before {
  color: black;
}

.castSocials .fa-circle {
  color: #fff;
}


.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}