.whatwedo-container {
  position: relative;
}

#solucoes {
  position: absolute;
  top: -54px;
}

.whatwedo-container > .section-heading {
  color: #EC6793;
  font-weight: 900;
  align-self: flex-end;
}

.wwd-heading {
  font-size: 60px;
  text-align: start;
  font-weight: bolder;
  color: #43276c;
  opacity: 0;
  font-family: 'Silka Bold';
}

@media screen and (max-width: 768px) {
  .wwd-heading {
    font-size: 40px;
  }
}

.solution > img {
  max-width: 60%;
  margin-bottom: 20px;
}
.solution > h3 {
  text-transform: uppercase;
  color: #43276c;
  font-family: 'Silka Bold';
}

.solution > p {
  font-size: 17px;
  line-height: 19px;
  color: #43276c;
  font-family: 'Silka';
}