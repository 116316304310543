@font-face {
  font-family: "Silka Bold";
  src: url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.eot");
  src: url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/738c5bf4433c36f5e4c16d55194b7d07.svg#Silka Bold")format("svg");
}

@font-face {
  font-family: "Silka";
  src: url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.eot");
  src: url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/314799d808058420a00fdd2500eb1aa3.svg#Silka")format("svg");
}

body {
  background-color: #fff;
}

.section-heading {
  font-size: 90px;
}

@media screen and (max-width: 768px) {
  .section-heading {
    font-size: 60px;
  }
}

@media screen and (max-width: 480px) {
  .section-heading {
    font-size: 50px;
  }
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #000;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}


/* animations */
.animate-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 1s forwards;
}

/* Fade Down */
.animate-down {
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeDown 1s forwards;
}

/* Fade Left */
.animate-left {
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeLeft 1s forwards;
}

/* Fade Right */
.animate-right {
  opacity: 0;
  transform: translateX(20px);
  animation: fadeRight 1s forwards;
}


/* FadeLeft Animation */
@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* FadeRight Animation */
@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* FadeUp Animation */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* FadeDown Animation */
@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

