.the_splash_container {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-width: 1920px;
  margin-inline: auto;
  background-color: black;
  overflow: hidden;
  padding: 0px !important;
  background-color: #43276C;
}

.the-splash {
  background-color: #43276C;
}

@media (max-width: 767px) and (orientation: portrait) {
  /* Your CSS rules for mobile devices in portrait orientation and smaller screens go here */
  .the_splash_container {
    width: 100%;
    aspect-ratio: 9/16;
    max-width: unset;
    margin-inline: auto;
    background-color: black;
    overflow: hidden;
    padding: 0px !important;
  }
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #6A00AA;
  opacity: 0.7;
  z-index: 1;
}

.the_splash_container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}



header .splash-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 1rem;
  mix-blend-mode: screen !important;
}

.hero-brand {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  padding: 1rem;
  color: white;
}

header > .splash-container > img {
  max-width: 1000px;
  mix-blend-mode: screen !important;
}

header > .splash-container > p {
	text-align: center;
	font-size: 1.5rem;
	line-height: 2rem;
	margin-top: 25px;
	text-shadow: 2px 2px 4px black;
	color: white;
  max-width: 900px;
}

@media screen and (max-width: 480px) {
  header > .splash-container > img {
    max-width: 600px;
  }

  .the_splash_container {
    padding: 10px;
    max-height: 90vh;
  }
  
  
}