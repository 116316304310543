.partners-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #43276c;
  padding-inline: 0;
  padding-block: 2rem;
  position: relative;
}

#parceiros {
  position: absolute;
  top: -150px;
}

.partners-container .title {
  max-width: 700px;
  margin-bottom: 3rem !important;
}

.slider-wrapper {
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.partners-heading {
  font-size: 60px;
  text-align: start;
  font-weight: bolder;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .partners-heading {
    font-size: 40px;
  }
}

.flex-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.slides-partners {
  position: relative;
}

.slides-partners::before,
.slides-partners::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 20px;
  z-index: 5;
}

.slides-partners::before {
  left: 0;
  background: linear-gradient(to right, #43276c, transparent);
}

.slides-partners::after {
  right: 0;
  background: linear-gradient(to left, #43276c, transparent);
}