section {
  padding-block: 4rem;
}

h1 {
	color: #f1f1f8;
	font: 700 2.5rem/2.875rem "Silka Bold", sans-serif;
}

h2 {
	color: #f1f1f8;
	font: 700 2.25rem/2.75rem "Silka Bold", sans-serif;
}

h3 {
	color: #f1f1f8;
	font: 700 1.75rem/2.125rem "Silka Bold", sans-serif;
}

h4 {
	color: #f1f1f8;
	font: 700 1.375rem/1.75rem "Silka Bold", sans-serif;
}

h5 {
	color: #f1f1f8;
	font: 700 1.125rem/1.625rem "Silka Bold", sans-serif;
}

h6 {
	color: #f1f1f8;
	font: 700 1rem/1.5rem "Silka Bold", sans-serif;
}

a {
	color: #f1f1f8;
	text-decoration: underline;
}

a:hover {
	color: #f1f1f8;
	text-decoration: underline;
}

a.turquoise {
	color: #cc00ff;
}

a.white {
	color: #fff;
}

p {
	font: 400  "Open Sans", sans-serif;
}