.contact-container {
  position: relative;
}

#contato {
  position: absolute;
  top: -54px;
}

.contact-container > .section-heading {
  font-weight: 900;
  color: #6a00aa;
  margin-bottom: 4rem !important;
}

.insta-img {
  -webkit-filter: drop-shadow(5px 5px 5px #999);
  filter: drop-shadow(5px 5px 5px #999);
}

.contact-container > H5 {
  line-height: 40px;
  font-weight: 800;
  font-size: 33px;
  color: #6a00aa;
  text-transform: uppercase;
}

.contact-container > h6 {
  font-weight: 800;
  font-size: 20px;
  color: #6a00aa;
  text-transform: uppercase;
  margin-top: 2rem !important;
}

.contact-text {
  max-width: 900px;
}

.fa-stack-1x::before {
  color: white;
  width: 200px;
}

.fa-stack-1x:hover::before {
  color: white;
}

.fa-circle {
  color: #43276c;
}

.fa-circle:hover::before {
  color: #e06090;
}

.socials-wrap {
  font-size: 40px;
}

@media screen and (max-width: 480px) {
  .socials-wrap {
    font-size: 30px;
  }

  .contact-container > H5 {
    font-size: 20px;
    text-align: right;
  }
  
  .contact-container > h6 {
    font-size: 15px;
    text-align: right;
  }
}

.contact-heading {
  font-size: 60px;
  text-align: start;
  font-weight: bolder;
  color: #43276c;
}

@media screen and (max-width: 768px) {
  .contact-heading {
    font-size: 40px;
  }
}

.social-heading {
  font-size: 20px;
  font-family: 'Silka';
}

label > .title {
  font-size: 20px;
  font-family: 'Silka Bold';
}

label > .sec {
  font-size: 15px;
  font-family: 'Silka';
  color: gray;
}

.form-control {
  font-family: 'Silka';
  border-radius: 20px;
  max-width: 70%;
  padding-block: .5rem;
  height: 48px;
}

@media screen and (max-width: 480px) {
  .form-control {
    max-width: 100%;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.enviar {
  border-radius: 15px !important;
  font-family: 'Silka';
  margin-top: 5px !important;
  background-color: #43276c !important;
}

.form-select {
  font-family: 'Silka';
  border-radius: 15px;
  max-width: 70%;
}