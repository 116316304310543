.projects-container {
  display: flex;
  position: relative;
}

#trabalhos {
  position: absolute;
  top: -54px;
}

.navigation-wrapper {
  position: relative;
}

.projects-container > .section-heading {
  color: #59C2B9;
  font-weight: 900;
  align-self: flex-end;
  margin-bottom: 4rem !important;
}

.proj-heading {
  font-size: 60px;
  text-align: start;
  font-weight: bolder;
  color: #43276c;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .proj-heading {
    font-size: 40px;
  }
}

.carouselzin {
  position: relative;
}

.proj-navigator {
  padding-inline: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  z-index: 5;
}

.proj-navigator > button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  width: 48px;
  height: 48px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
