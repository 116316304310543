.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 2rem;
  color: white;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  position: fixed;
}

.navbar-container > .brand {
  width: 100px;
}

/* Tablet and above */

@media (min-width: 768px) {
  .navbar-container {
    background-color: "#5A3495";
  }
}

.glassy {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.17) !important;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.linkosos > a {
  margin-right: 15px;
  text-decoration: none;
  font-family: 'Silka';
  text-transform: uppercase;
}

.linkosos > a:hover {
  color: #59C2B9;
  text-decoration: underline ;
}

.burger {
  font-size: 20px;
}

.abs-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #43276c;
  text-decoration: none;
  top: 40px;
  padding-inline: 1rem;
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  height: 0;
  border-radius: 15px;
}

.abs-menu > a {
  font-family: 'Silka';
  margin-top: 5px;
  text-decoration: none;
}

.showing-menu {
  height: 215px;
}

.wrapper-menu {
  width: 0;
  height: 0;
  position: relative;
}