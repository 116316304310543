.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 600px;
  height: fit-content;
  border-radius: 20px;
  text-align: center;
  box-shadow: none;
  position: relative;
  cursor: pointer;
}

.title {
  background-color: white;
  position: absolute;
  bottom: -45px;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  font-family: 'Silka';
  border: solid 1px #43276C;
}

.text {
  text-align: center;
  width: auto;
  height: 100%;
  border: solid 1px purple;
  border-radius: 15px;
  padding-block: .5rem;
  background-color: white;
  margin-top: -30px !important;
}

@media screen and (max-width: 768px) {
  .card {
    width: 275px;
  }
}

.card img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
