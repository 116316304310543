.mma-container {
  background-image: url('../../assets/images/mmafundo.webp');
  background-repeat: repeat;
  background-size: contain;
  padding-block: 0;
}
.mma-heading {
  font-size: 60px;
  text-align: start;
  font-weight: bolder;
  color: #fff;
  opacity: 0;
}

.tapeup {
  padding: 0;
  margin: 0;
  background-image: url('../../assets/images/fita_up.png');
  background-repeat: repeat-x;
  background-size: 1024px;
  height: 140px;
  background-position: top;
}
.mma-desc {
  font-size: 30px;
  color: white;
  font-family: 'Silka';
  line-height: 35px;
  margin-top: 2rem;
}

.tape {
  padding: 0;
  margin: 0;
  background-image: url('../../assets/images/fita.png');
  background-repeat: repeat-x;
  background-size: 1024px;
  height: 140px;
  background-position: bottom;
}

@media screen and (max-width: 768px) {
  .mma-heading {
    font-size: 40px;
  }
}

.mma-card > span {
  color: white;
  font-family: 'Silka Bold';
  font-size: 20px;
  background-color: purple;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border-radius: 12px;
}

.mma-card > img {
  aspect-ratio: 1/1;
  margin-bottom: -15px;
}

.mma-card {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
}


.timeline-line {
  background-color: white;
  height: 5px;
  position: absolute;
  width: 80%;
  top: 50%;
  transform: translateY(calc(-50% - 1.5rem));
  z-index: 0; /* Coloque atrás das colunas */
}

@media screen and (max-width: 1024px) {
  .timeline-line {
    display: none;
  }

}

.saibamais {
  text-decoration: none;
  font-family: 'Silka';
  background: purple;
  color: white;
  border-color: purple;
  margin-top: 1rem;
}