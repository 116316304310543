.data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 1920px;
  margin-inline: auto;
  padding-block: 0;
  margin-bottom: 2rem;
}

.navigation-wrapper {
  position: relative;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #000;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(0, 0, 0, 0.5);
}

.projects-container > .section-heading {
  color: #59C2B9;
  font-weight: 900;
  align-self: flex-end;
  margin-bottom: 4rem !important;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  margin-top: -30px;
}
.dot {
  border: none;
  width: 15px;
  height: 15px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: transparent;
  border: solid 3px #c5c5c5;
}

@media screen and (min-width: 1024px) {
  .slide-data {
    padding-inline: 50px;
  }

  .dots {
    margin-top: -100px;
  }

  .dot {
    width: 17px;
    height: 17px;
  }
}

.slide-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}