.whoweare-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: -6rem;
  position: relative;
}

#sobre {
  position: absolute;
  top: -54px;
}

.whoweare-text > em {
  color: #43276c;
}

.whoweare-heading {
  color: #43276c;
  text-transform: uppercase;
  font-size: 35px !important;
  line-height: 40px !important;
}

.whoweare-text {
  font-size: 30px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 2rem;
  color: black;
  font-family: 'Silka', sans-serif;
  line-height: 36px;
}

@media screen and (max-width: 768px) {
  .whoweare-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {
  .whoweare-heading {
    font-size: 20px;
    line-height: 27px;
  }

  .whoweare-text {
    font-size: 20px;
    line-height: 25px;
  }
}